import Vue from 'vue'

import configPlugin from '../config'

Vue.use(configPlugin)

const readyCallback = function (event) {
  if (event.data.action !== 'init') return console.warn('Callback has not been removed')
  Vue.prototype.__worker.ready = event.data.status === 200
  Vue.prototype.__worker.removeEventListener('message', readyCallback)
}

const path = location.href.indexOf('dgtek-portal') > 0 ? 'dgtek-portal/' : ''

window[Symbol.for('portal.worker')] = new Worker(`/${path}portal.worker.js`)

Object.assign(Vue.prototype, {
  __worker: window[Symbol.for('portal.worker')],
  $openExternalLink: (url) => window.open(url, '_blank'),
  $openInternalLink: (url) => window.open(url, '_self')
})

Vue.prototype.__worker.addEventListener('message', readyCallback)

const { $apiKey, $apiSecret, $adminApiKey, $adminCreds } = Vue.prototype

Vue.prototype.__worker.postMessage({
  action: 'init',
  host: location.origin === Vue.prototype.$apiHost() ? Vue.prototype.$apiHost() : Vue.prototype.$stagingHost(),
  key: $apiKey(),
  secret: $apiSecret(),
  adminApiKey: $adminApiKey(),
  admin: $adminCreds()
})

export default Vue
