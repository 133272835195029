import { eventsTable } from '../methods'
import { events } from '../events'

export const validationCallback = function (data) {
  if (!events[data.action]) return console.warn('Unknown event', data)

  if (typeof eventsTable[events[data.action]] !== 'function') return console.warn('Callback not defined', data)

  const callback = eventsTable[events[data.action]]
  delete eventsTable[events[data.action]]

  callback(data)
}
