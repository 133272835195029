import { eventsTable } from './eventsTable'
import { events } from '../events'

import {
  emails,
  phones,
  phonesWork,
  phonesMobile
} from './data'

const middleware = function (callback, data) {
  Object.assign(emails, data.emails)
  Object.assign(phones, data.phones)
  Object.assign(phonesWork, data.phonesWork)
  Object.assign(phonesMobile, data.phonesMobile)

  if (typeof callback === 'function') callback(Array.isArray(emails) && Array.isArray(phones) && Array.isArray(phonesWork) && Array.isArray(phonesMobile))
}

const action = 'users'

export const refreshData = (callback) => {
  Object.assign(eventsTable, { [events[action]]: middleware.bind(null, callback) })
  window[Symbol.for('portal.worker')].postMessage({ action })
}
