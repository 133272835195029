import { rules } from '@/config'

export const getRegistrationResult = function (data) {
  const result = {}
  let error = false

  for (const sectionName in data) {
    result[sectionName] = {}
    for (const propName in data[sectionName]) {
      let { value, type, required } = data[sectionName][propName]

      if (required) {
        if (!value) {
          error = true
        } else {
          if (rules[type]) {
            if (typeof rules[type](value) === 'string') {
              error = true
            }
          }
        }
      }

      if (error) break

      if (type === 'phone' || type === 'mobile') {
        value = value.split(' ').join('').split('-').join('')
        if (type === 'mobile') {
          value = `+61${value}`
        }
      }

      result[sectionName][propName] = value
    }
  }
  return {
    error,
    result: error ? null : result
  }
}
