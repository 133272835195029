import Vue from './vue-extended'
import router from './router'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import { hostHandler, apiHandler, roleHandler, validationCallback, debugerCallback } from '@/helpers'

import ErrorMessage from '@/components/popups/error.vue'
import Message from '@/components/popups/message.vue'

import 'dgtek-styles/css/fonts.scss'
import 'dgtek-styles/css/variables.scss'

import 'simple-google-autocomplete'

import 'dgtek-portal-calculator'
import 'dgtek-portal-calculator/dist/dgtek-portal-calculator.css'

Vue.config.productionTip = false

Vue.component('error-message', ErrorMessage)
Vue.component('simple-message', Message)

const validators = ['users', 'validate-email', 'validate-phone', 'validate-phone-work', 'validate-phone-mobile']

const instance = new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

instance.dispatchProgressEvent = function (value) {
  instance.$emit('progress-event', { progress: value })
}

instance.__worker.dispatchProgressEvent = instance.dispatchProgressEvent

instance.sendMessageToWorker = function (message) {
  instance.dispatchProgressEvent(true)
  instance.__worker.postMessage(message)
}

instance.__worker.onmessage = function (event) {
  this.dispatchProgressEvent(false)
  const { status, action, result } = event.data

  if (status === 300) return debugerCallback(event)

  if (action === 'init' || status !== 200) return

  if (action === 'redirect') {
    const { role } = result
    roleHandler(role)
    hostHandler(location.origin)
    apiHandler(location.origin === 'https://portal.dgtek.net' ? location.origin : 'http://portal.staging.dgtek.net')
  }

  if (validators.includes(action)) return validationCallback(event.data)
}

instance.__authorize = function (login, password) {
  if (!login || !password) return
  instance.sendMessageToWorker({ action: 'auth', login, password })
}

instance.__registrate = function (data) {
  instance.sendMessageToWorker({ action: 'registrate', data })
}
