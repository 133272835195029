const secretHandler = (secret => secret).bind(null, process.env.VUE_APP_SECRET)

const stagingHostHandler = (host => host).bind(null, 'https://portal.staging.dgtek.net')

const hostHandler = ((prod, staging) => process.env.NODE_ENV === 'production' ? prod : staging)
  .bind(null, 'https://portal.dgtek.net', 'https://portal.staging.dgtek.net')

const apiKeyHandler = (key => key).bind(null, process.env.VUE_APP_KEY)

const telegramBotCodeHandler = (code => code).bind(null, process.env.VUE_APP_TELEGRAM_CODE)

const adminApiKeyHandler = (key => key).bind(null, process.env.VUE_APP_ADMIN_API_KEY)

const adminCredentialsHandler = (creds => creds).bind(null, process.env.VUE_APP_ADMIN_CREDENTIALS)

const config = {
  apiSecret: secretHandler,
  stagingHost: stagingHostHandler,
  apiHost: hostHandler,
  apiKey: apiKeyHandler,
  botCode: telegramBotCodeHandler,
  adminApiKey: adminApiKeyHandler,
  adminCreds: adminCredentialsHandler
}

export {
  config
}

export default {
  install (Vue) {
    Vue.appConfig = config
    Vue.stagingHost = stagingHostHandler
    Vue.apiHost = hostHandler
    Vue.apiSecret = secretHandler
    Vue.apiKey = apiKeyHandler
    Vue.botCode = telegramBotCodeHandler
    Vue.adminApiKey = adminApiKeyHandler
    Vue.adminCreds = adminCredentialsHandler
    Vue.prototype.$appConfig = config
    Vue.prototype.$apiSecret = secretHandler
    Vue.prototype.$apiKey = apiKeyHandler
    Vue.prototype.$stagingHost = stagingHostHandler
    Vue.prototype.$apiHost = hostHandler
    Vue.prototype.$botCode = telegramBotCodeHandler
    Vue.prototype.$adminApiKey = adminApiKeyHandler
    Vue.prototype.$adminCreds = adminCredentialsHandler
  }
}
