import { patterns, states } from './'
import { validateABN } from '../helpers'
import { emails, phones, phonesWork, phonesMobile } from '../methods/data'

const normalize = phone => phone.split('-').join('').split(' ').join('')

const testEmail = email => !emails.includes(email) || 'Email already registered'
const testPhone = phone => !(phones.includes(normalize(phone)) || phonesWork.includes(normalize(phone))) || 'Phone already in use'
const testMobile = mobile => !phonesMobile.includes(`+61${normalize(mobile)}`) || 'Phone already in use'

export const rules = {
  required: value => !!value || 'Required.',
  'simple-text': value => true,
  number: value => value ? patterns.number.test(value) || 'Invalid number.' : true,
  phone: value => value ? !patterns.phone.test(value) ? 'Invalid phone number.' : testPhone(value) : true,
  mobile: value => value && !patterns.mobile.test(value) ? 'Invalid mobile phone number.' : testMobile(value),
  email: value => value ? !patterns.email.test(value) ? 'Invalid e-mail.' : testEmail(value) : true,
  state: value => value ? states.indexOf(value) !== -1 || 'Invalid state.' : true,
  postcode: value => value ? patterns.postcode.test(value) || 'Invalid state.' : true,
  abn: value => validateABN(value) || 'Invalid ABN.',
  url: value => value ? patterns.url.test(value) || 'Invalid URL.' : true,
  password: value => value ? patterns.password.test(value) || 'Password is not strong enough.' : true
}
